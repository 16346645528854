<template>
<div class="content messages">

    <div class="con">

        <div class="message-list">
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
            <button class="message-list-item">
                <div class="cover">
                    <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                </div>
                <div class="info">
                    <h4>11/C Sayılal Bölümü</h4>
                    <p>Deneme bir kaç kelimelik cümle</p>
                </div>
            </button>
        </div>

        <div class="message-content" style="right: -100%;">
            <div class="top">
                <i class="fas fa-chevron-left"></i>
                <img src="https://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_400x400.jpg" alt="">
                <h4>11/C Sayısal Bölümü</h4>
            </div>
            <div class="main">

                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <span>23.07.2021 23:48</span>
                        <h5>Kürşad Şimşek</h5>
                        <p>Slm :)</p>
                    </div>
                </div>

                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente voluptate suscipit
                            molestias dolorum est. Asperiores expedita beatae aliquam dolorum, iste obcaecati quas
                            reiciendis eaque qui in tempore dolor commodi. Asperiores!</p>
                    </div>
                </div>
                <div class="message-box message-box-me">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <span>23.07.2021 23:48</span>
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                        <p>noldu</p>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box message-box-me">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <span>23.07.2021 23:48</span>
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <img src="https://www.webailesi.com/upload/cdn/78/5e84da09b333b_1579461169_preview_recent_best.jpg" alt="">
                        <p>noldu</p>
                    </div>
                </div>
                <div class="message-box">
                    <div class="cover">
                        <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    </div>
                    <div class="info">
                        <h5>Kürşad Şimşek</h5>
                        <p>noldu</p>
                    </div>
                </div>

            </div>
            <div class="bot">
                <div class="img-add">
                    <img src="https://media-exp1.licdn.com/dms/image/C4D03AQGmECFMwxwTNw/profile-displayphoto-shrink_100_100/0/1595678972749?e=1621468800&v=beta&t=e5hCRC3t6g0tRsppV0PVtLyrvVv951UzQN1QFL6DsqE" alt="">
                    <span>deneme-resim_adı__000001.jpg</span>
                    <i class="far fa-times" style="font-size: 24px; margin-left: auto;"></i>
                </div>
                <div class="bot" style="flex-wrap: nowrap;">
                    <i class="fad fa-camera-alt"></i>
                    <input type="text" placeholder="Mesaj yazın">
                    <i class="fad fa-paper-plane"></i>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {

}
</script>

<style scoped>
body {
    --font-size: 16px;
    --main-font-family: "Mulish", sans-serif;
    --title-font-family: "Montserrat", sans-serif;

    --border-radius-2: 2px;
    --border-radius-4: 4px;
    --border-radius-8: 8px;
    --border-radius-96: 96px;

    --transition-15: 0.15s ease-in-out;
    --transition-30: 0.3s ease-in-out;

    --box-shadow-1: 0px 0.125em 3em rgba(0, 0, 0, 0.05);
    --box-shadow-2: 0px 0.25em 3em rgba(0, 0, 0, 0.1);

    --alpha-05: 0.05;
    --alpha-15: 0.15;
    --alpha-25: 0.25;
    --alpha-50: 0.5;
    --alpha-75: 0.75;
    --alpha-85: 0.85;

    --main-color-1: #3333ff;
    --main-color-2: #0000ff;
    --main-color-3: #6666ff;

    --background-color-1: #11151c;
    --background-color-2: #151922;
    --background-color-3: #171b25;
    --background-color-4: #181d28;

    --text-color-1: #ffffff;
    --text-color-2: #eeeeee;
    --text-color-3: #cccccc;
    --text-color-4: #aaaaaa;
    --text-color-5: #888888;

    --background-color-1-rgb: 18, 18, 18;
}

.content {
    width: auto;
    height: auto;
    max-height: 100vh;
    display: flex;
    padding-left: 0em;
    padding-right: 0em;
    margin-left: 110px;
}

/* ---------------------------  Messages Start  ----------------------------------- */
@media (min-width: 0px) {
    .messages {
        width: 100vw;
        height: calc(100vh - (74px + 80px));
        max-height: inherit;
        display: flex;
        padding: 0em;
        box-shadow: var(--box-shadow-2);
    }

    .vertical .messages {
        height: 100vh !important;
    }

    .messages .con {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        border-radius: var(--border-radius-8);
        overflow: inherit;
        box-shadow: var(--box-shadow-1);
    }

    .messages .message-list {
        width: 100%;
        height: auto;
        min-height: 100%;
        background-color: var(--background-color-1);
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        overflow: auto;
    }

    .messages .message-list .message-list-item {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        background-color: var(--background-color-1);
        border: none;
        border-bottom: 2px solid var(--background-color-4);
        transition: var(--transition-15);
        cursor: pointer;
        padding: 0.75em;
    }

    .messages .message-list .message-list-item:hover {
        background-color: var(--background-color-2);
    }

    .messages .message-list .message-list-item .cover {
        width: 64px;
        height: 64px;
        display: flex;
        flex-shrink: 0;
        border-radius: var(--border-radius-96);
        overflow: hidden;
    }

    .messages .message-list .message-list-item .cover img {
        width: 100%;
        height: auto;
        display: flex;
    }

    .messages .message-list .message-list-item .info {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1em;
        padding-right: 1em;
    }

    .messages .message-list .message-list-item .info h4 {
        line-height: 1.5em;
        font-size: 1em;
        font-weight: 700;
        color: var(--text-color-1);
    }

    .messages .message-list .message-list-item .info p {
        line-height: 1.5em;
        font-size: 0.85em;
        font-weight: 600;
        color: var(--text-color-3);
    }

    .message-content {
        position: absolute;
        right: 0;
        width: 100%;
        height: auto;
        min-height: calc(100% - 80px + 80px);
        display: flex;
        flex-direction: column;
        transition: var(--transition-15);
    }

    .message-content .top {
        position: sticky;
        top: 74px;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        padding: 1em;
        background-color: var(--background-color-1);
        z-index: 10;
    }

    .message-content .top i {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25em;
        color: var(--text-color-2);
        background-color: transparent;
        border-radius: var(--border-radius-96);
        transition: var(--transition-15);
        margin-right: 0.5em;
        cursor: pointer;
        flex-shrink: 0;
    }

    .message-content .top i:hover {
        background-color: var(--background-color-4);
    }

    .message-content .top img {
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: var(--border-radius-96);
        margin-right: 1em;
    }

    .message-content .top h4 {
        line-height: 1.5em;
        font-size: 1em;
        font-weight: 700;
        color: var(--text-color-1);
    }

    .message-content .main {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: var(--background-color-2);
        padding: 1.5em;
        overflow: auto;
    }

    .message-content .main .message-box {
        width: 100%;
        max-width: 480px;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1.75em;
    }

    .message-content .main .message-box .cover {
        width: auto;
        height: auto;
        display: flex;
        flex-shrink: 0;
        border-radius: var(--border-radius-96);
        overflow: hidden;
    }

    .message-content .main .message-box .cover img {
        width: 48px;
        height: 48px;
        display: flex;
    }

    .message-content .main .message-box .info {
        position: relative;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: var(--background-color-4);
        border-radius: 12px;
        border-top-left-radius: 0;
        padding: 0.5em 1em;
        margin-left: 0.75em;
    }

    .message-content .main .message-box .info span {
        position: absolute;
        top: calc(100% + 0.25em);
        left: 0.25em;
        line-height: 1.5em;
        font-family: var(--title-font-family);
        font-size: 0.7em;
        font-weight: 600;
        color: var(--text-color-3);
    }

    .message-content .main .message-box.message-box-me .info span {
        left: auto;
        right: 0.25em;
    }

    .message-content .main .message-box .info h5 {
        line-height: 1.5em;
        font-size: 1em;
        font-weight: 700;
        color: var(--text-color-2);
    }

    .message-content .main .message-box .info p {
        line-height: 1.25em;
        font-size: 0.85em;
        font-weight: 700;
        color: var(--text-color-2);
        margin-top: 0.25em;
    }

    .message-content .main .message-box .info img {
        width: 100%;
        height: auto;
        max-height: 200px;
        object-fit: cover;
        display: flex;
        margin-top: 1em;
        margin-bottom: .5em;
    }

    .message-box-me {
        flex-direction: row-reverse !important;
        margin-left: auto;
    }

    .message-box-me .info {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 0 !important;
        background-color: var(--main-color-1) !important;
        margin-right: 0.75em;
    }

    .message-content .bot {
        position: sticky;
        bottom: 0;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 1em;
        background-color: var(--background-color-1);
    }

    .img-add {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 1em;
        padding-right: 1em;
    }

    .img-add img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        display: flex;
        border-radius: var(--border-radius-8);
    }

    .img-add span {
        display: none;
        list-style: 1.5em;
        font-size: 1em;
        font-weight: 500;
        color: var(--text-color-1);
        margin-left: 1em;
        margin-right: 1em;
    }

    @media (min-width: 992px) {
        .img-add span {
            display: flex;
        }
    }

    .message-content .bot i {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color-2);
        background-color: var(--background-color-2);
        border-radius: var(--border-radius-96);
        transition: var(--transition-15);
        cursor: pointer;
        flex-shrink: 0;
    }

    .message-content .bot i:hover {
        color: var(--text-color-1);
        background-color: var(--background-color-4);
    }

    .message-content .bot input {
        width: 100%;
        height: 48px;
        background-color: var(--background-color-2);
        border-radius: var(--border-radius-96);
        transition: var(--transition-15);
        margin-left: 1em;
        margin-right: 1em;
    }

    .message-content .bot input:hover {
        background-color: var(--background-color-3);
    }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {
    .messages {
        padding: 2em;
        height: calc(100vh - 85px) !important;
    }

    .messages .con {
        overflow: hidden;
        height: 100%;
    }

    .messages .message-list {
        width: 360px;
    }

    .message-content {
        position: initial;
    }

    .message-content .top {
        top: 0;
    }
}

/* ---------------------------  Messages Finish  ----------------------------------- */
</style>
